<!--
 * @Author: lzh
 * @Date: 2022-07-05 15:43:17
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-12 16:42:57
 * @Description: file content
-->
<template>
  <div class="edit-try-to-contact-box">
    <div class="cell-b">
      <h3>標題配置</h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入標題-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}title`)"
          type="textarea"
          rows="3"
        ></el-input>
      </div>
      <h3>描述配置
        <selfTips :content="$tipText.textWrap" />
      </h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}desc`]"
          :placeholder="`請輸入描述-${lItem.name}`"
          type="textarea"
          rows="3"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}desc`)"
        ></el-input>
      </div>

      <h3>對其方式</h3>
      <template>
        <el-select
          v-model="configs.descAlign"
          placeholder="请选择"
          @change="setValue('descAlign')"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </template>

    </div>
    <div
      class="cell-b"
      style="margin-top:10px;"
    >
      <h3>按鈕設置</h3>
      <div
        v-for="(item, index) in configs.btns"
        :key="index"
      >
        <selfCell title="按鈕-文字">
          <div>
            <el-input
              v-for="lItem in language"
              :key="lItem.code"
              v-model="item[`${lItem.code=='HK'?'':lItem.code}title`]"
              :placeholder="`請輸入標題-${lItem.name}`"
              @input="setValue('btns', index)"
            ></el-input>
          </div>
        </selfCell>
        <selfCell title="按鈕-跳轉">
          <selfPagePicker
            v-model="item.go"
            @onChange="setValue('btns')"
          />
        </selfCell>
      </div>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
export default {
  name: 'edit-try-to-contact',
  mixins: [langEditMixin],
  props: {
    configs: {
      default () {
        return {
          title: '',
          btns: [
            {
              title: '',
            },
            {
              title: '',
            },
          ],
        };
      },
    },
  },
  data () {
    return {
      options: [{
        value: 'left',
        label: '左對齊'
      }, {
        value: 'center',
        label: '居中對齊'
      }, {
        value: 'right',
        label: '右對齊'
      }],
    }
  },
  methods: {
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
  },
};
</script>

<style lang="less" scoped></style>
