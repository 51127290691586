import { render, staticRenderFns } from "./tryToContact.vue?vue&type=template&id=b7824d74&scoped=true"
import script from "./tryToContact.vue?vue&type=script&lang=js"
export * from "./tryToContact.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7824d74",
  null
  
)

export default component.exports